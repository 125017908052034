<template>
<div>
            <v-card-title class="display-1 mb-2">Request a charger</v-card-title>
            <v-card-text>
              Upload photos of the following: <br>
              Junction box (please make sure the fuse is visible) <br>
              Fuse box from a distance to give us a clear view of the area around it <br>
              Junction box with open door <br>
              Exact location of the charge point <br>
              Back of the internet modem (where the cable are located)
              <v-form ref="form" v-model="isFormValid" lazy-validation>
                <v-textarea
                  name="input-7-1"
                  label="Which type of car do you have / wil soon be yours ?"
                  value="[TODO]"
                  hint="Your car brand and model"
                ></v-textarea>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
        <v-btn
            color="primary"
            text
            @click="previousStep()"
        >
            <v-icon>mdi-chevron-left</v-icon>
            back
        </v-btn>
        <v-btn
            color="success"
            text
            @click="nextStep()"
        >
            Next
        </v-btn>
        <v-btn
            color="red"
            text
        >
            Cancel
        </v-btn>
    </v-card-actions>
</div>
</template>

<script>

export default {
  data () {
    return {
      isDiffAdrs: false,
      isSolarPanels: false,
      isCPOut: false,
      langs: ['en', 'de'],

      // sign in buttons
      isLoading: false,
      isSignInDisabled: false,

      // form
      isFormValid: true,
      email: '',
      password: '',

      // form error
      error: false,
      errorMessages: '',

      errorProvider: false,
      errorProviderMessages: '',

      // show password field
      showPassword: false,

      isVerified: false,
      // input rules
      rules: {
        required: (value) => (value && Boolean(value)) || 'Required'
      }
    }
  },
  mounted () {
    this.isVerified = this.$route.query.Verified
    this.loadConfigPage()
  },
  methods: {
    nextStep () {
      this.$store.commit('requestsState/setStep', 'SubscriptionType')
    },
    previousStep () {
      this.$store.commit('requestsState/setPreviousStepName')
    },
    forgetPswdLink () {
      // this.$router.push({ name: 'auth-forgot', query: { returnUrl: this.$route.query.ReturnUrl } })
    },
    signupLink () {
      // this.$router.push({ name: 'auth-signup', query: { returnUrl: this.$route.query.ReturnUrl } })
    },
    async loadConfigPage () {
      /* const params = this.$route.query
      try {
        const rslt = await new AuthService.getLoginPage(params.ReturnUrl)
        // TODO
        console.log('[DEBUG CONFIG]')
        console.log(rslt)
      } catch (error) {
        console.log(error)
      } */
    },
    submit () {
      if (this.$refs.form.validate()) {
        this.isLoading = true
        this.isSignInDisabled = true
        this.signIn()
      }
    },
    async signIn () {
      /* const params = this.$route.query
      // TODO CHECK if return url exist
      try {
        const payload = {
          username: this.email,
          password: this.password,
          rememberLogin: true,
          returnUrl: this.$route.query.ReturnUrl,
          altUsername: ''
        }
        const rslt = await new AuthService.login(payload)
        if (rslt.data.type === 'Verification') {
          console.log('DEBUG : VERIFICATION')
          // this.$router.push(`/auth/verify-email?email=${rslt.data.email}`)
          console.log(rslt.data.email)
          this.$router.push({ name: 'auth-verify-email', query: { email: rslt.data.email } })
          // this.$router.push('/auth/verify-email')
        } else if (rslt.data.isOk) {
          window.location = rslt.data.redirectUrl
        } else {
          this.resetErrors()
          this.isLoading = false
          this.isSignInDisabled = false
        }
        console.log(rslt)
      } catch (error) {
        console.log(error)
      } */
    },
    resetErrors () {
      this.error = false
      this.errorMessages = ''

      this.errorProvider = false
      this.errorProviderMessages = ''
    }
  }
}
</script>
